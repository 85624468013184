! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("opened");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("opened");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".main-content-wrapper").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".dropdown:has('.dropdown-wrapper')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .dropdown-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	/* eslint-disable */
	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions: Init
	 */
	Webflow.require('ix').init([
		{"slug":"brand-load","name":"Brand Load","value":{"style":{"opacity":0,"x":"-500px","y":"0px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"opacity":1,"transition":"transform 1250ms ease 0, opacity 1500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"search-load","name":"Search Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"300ms"},{"opacity":1,"transition":"transform 1250ms ease 0, opacity 1500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"intro-load","name":"Intro Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"450ms"},{"opacity":1,"transition":"transform 1250ms ease 0, opacity 1500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"minislideshow-load","name":"MiniSlideshow Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"450ms"},{"opacity":1,"transition":"transform 1000ms ease-in-out 0, opacity 1000ms ease-in-out 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}}
	]);
	/* eslint-enable */
});
