(function($) {
	var pluginName = "accessibleMenu";

	function Plugin(element) {
		var el = element;
		var $el = $(element);

		function init() {
			$el.find("a").on("focus", function() {
				focusSubmenu($(this).parents("li").last());

				attachKeypressBindings();
			});
		};

		function collapseSubmenus() {
			$el.find("li").removeClass("open");
		};

		function expandSubmenu($parent) {
			$parent.addClass("open");
		};

		function focusSubmenu($parent) {
			collapseSubmenus();
			expandSubmenu($parent);
		};

		function attachKeypressBindings(processEvent) {
			$(document).off("keydown").on("keydown", function(event) {
				var $submenu = $(event.target).parents("li").last();

				if ($submenu) {
					switch (event.which) {
						case 38: // Up
							goToPreviousItem($submenu);
							break;

						case 40: // Down
							goToNextItem($submenu);
							break;

						case 37: // Left
							goToPreviousMenu($submenu);
							break;

						case 39: // Right
							goToNextMenu($submenu);
							break;

						case 9: // Tab
							closeSubmenuIfDoesNotContainTheFocusedElement();
							break;
					}

					if (event.which >= 37 && event.which <= 40) {
						event.preventDefault();
					}
				}
			});
		};

		function closeSubmenuIfDoesNotContainTheFocusedElement() {
			setTimeout(function() {
				if (!$.contains($el.get(0), document.activeElement)) {
					collapseSubmenus();
				}
			}, 100);
		};

		function goToPreviousMenu($dropDownMenu) {
			var $previousMenu = $dropDownMenu.prevAll("li:first");
			getNavLink($previousMenu).focus();
		};

		function goToNextMenu($dropDownMenu) {
			var $nextMenu = $dropDownMenu.nextAll("li:first");
			getNavLink($nextMenu).focus();
		};

		function getNavLink($menuItem) {
			return $menuItem.find("a:first");
		};

		function goToPreviousItem($submenu) {
			$focusedElement = $submenu.find("div").find(":focus");

			if ($focusedElement.length == 0) {
				goToFirstItem($submenu);
			}
			else {
				var allAnchors = $focusedElement.parent().parent().find("a");
				var index = allAnchors.index($focusedElement);

				if (index > 0) {
					allAnchors[index-1].focus();
				}
				else if (index == 0 && $submenu.find("a")) {
					$submenu.find("a").first().focus();
				}
			}
		};

		function goToNextItem($submenu) {
			$focusedElement = $submenu.find("div").find(":focus");

			if ($focusedElement.length == 0) {
				goToFirstItem($submenu);
			}
			else {
				var allAnchors = $focusedElement.parent().parent().find("a");

				var index = allAnchors.index($focusedElement);
				if (index + 1 < allAnchors.length) {
					allAnchors[index+1].focus();
				}
			}
		};

		function goToFirstItem($submenu) {
			focusSubmenu($submenu);
			$submenu.find("div a:first").focus();
		};

		init();
	};

	$.fn[pluginName] = function(options) {
		if (typeof arguments[0] === "string") {
			var methodName = arguments[0];
			var args = Array.prototype.slice.call(arguments, 1);
			var returnVal;

			this.each(function() {
				if ($.data(this, 'plugin_' + pluginName) && typeof $.data(this, 'plugin_' + pluginName)[methodName] === 'function') {
					returnVal = $.data(this, 'plugin_' + pluginName)[methodName].apply(this, args);
				}
				else {
					throw new Error('Method ' +  methodName + ' does not exist on jQuery.' + pluginName);
				}
			});

			if (returnVal !== undefined){
				return returnVal;
			}
			else {
				return this;
			}
		}
		else if (typeof options === "object" || !options) {
			return this.each(function() {
				if (!$.data(this, 'plugin_' + pluginName)) {
					$.data(this, 'plugin_' + pluginName, new Plugin(this, options));
				}
			});
		}
	};
})(jQuery);
